import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Box, Dialog, InputBase, NativeSelect, Stack, Typography, styled } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { useDispatch, useSelector } from '../../../../redux/store';
import { openAddMemberBankDialog } from '../../../../redux/slices/addMemberBankDialog';

const CustomSelect = styled(NativeSelect)(({ theme }) => ({
  width: '100%',
  padding: '4px 11px',
  marginTop: 1,
  borderRadius: '5px',
  border: '1px solid ',
  background: '#fff',
  color: '#271111',
}));

const WithdrawFormDialog = () => {
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const [bankList, setBankList] = useState([]);

  const [errors, setErrors] = useState([]);

  const [newBankAccountForm, setNewBankAccountForm] = useState({
    accountName: '',
    bank: '',
    accountNumber: '',
    ifscCode: '',
  });

  useEffect(() => {
    getBankList();
  }, []);

  const selectedBank = useMemo(() => {
    if (newBankAccountForm?.bank && bankList?.length) {
      const _bank = bankList.find((x) => x?.id === newBankAccountForm.bank);

      return _bank;
    }

    return null;
  }, [newBankAccountForm?.bank, bankList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBankAccountForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const getBankList = () => {
    conObj
      .post('transactions/supported_banks.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setBankList(res.data?.data?.data[0].Bank);
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  const addMemberBank = () => {
    if (isValid()) {
      setIsButtonDisabled(true);

      let _bankAccountNo = newBankAccountForm?.accountNumber.trim();

      if (selectedBank?.name === 'UPI') _bankAccountNo = `${_bankAccountNo?.replace('@upi', '')}@upi`;

      const requestData = {
        'member_bank-type': 'bank',
        'member_bank-bank_id': newBankAccountForm?.bank,
        'member_bank-account_name': newBankAccountForm?.accountName.trim(),
        'member_bank-account_number': _bankAccountNo,
        ...(hasIFSCBank() && { 'member_bank-bank_branch': newBankAccountForm?.ifscCode }),
      };

      conObj
        .post('members/bank_add.json', requestData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data?.status === 1) {
              isAddMemberBankDialog?.onClose();

              dispatch(openAddMemberBankDialog({ open: false, onClose: null }));
            }
          }
        })
        .catch((err) => {
          setIsButtonDisabled(false);
          console.log(`Server Error: ${err.message}`);
          enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
        });
    }
  };

  const isValid = () => {
    let valid = true;

    const _errors = {};

    if (!newBankAccountForm.accountName.trim()) {
      valid = false;
      _errors.accountName = translate('x_is_required', { x: translate('account_name') });
    } else if (newBankAccountForm.accountName.trim().length < 5) {
      valid = false;
      _errors.accountName = translate('account_name_must_be_4');
    } else {
      _errors.accountName = '';
    }

    if (!newBankAccountForm.bank) {
      valid = false;
      _errors.bank = translate('please_select_x', { x: translate('bank_account') });
    } else {
      _errors.bank = '';
    }

    const accNumberLenght = newBankAccountForm.accountNumber.trim().length;
    const selectedBank = bankList.find((f) => f.id === newBankAccountForm?.bank);

    if (!newBankAccountForm.accountNumber.trim()) {
      valid = false;
      _errors.accountNumber = translate('x_is_required', {
        x: selectedBank?.name === 'UPI' ? translate('upi_id') : translate('account_number'),
      });
    } else if (parseInt(selectedBank?.min_length, 10) > 0 || parseInt(selectedBank?.max_length, 10) > 0) {
      if (parseInt(selectedBank?.min_length, 10) > 0 && accNumberLenght < parseInt(selectedBank?.min_length, 10)) {
        valid = false;
        _errors.accountNumber = translate('minimum_length_is_x', { x: selectedBank?.min_length });
      } else if (
        parseInt(selectedBank?.max_length, 10) > 0 &&
        accNumberLenght > parseInt(selectedBank?.max_length, 10)
      ) {
        valid = false;
        _errors.accountNumber = translate('maximum_length_is_x', { x: selectedBank?.max_length });
      } else {
        _errors.accountNumber = '';
      }
    } else {
      _errors.accountNumber = '';
    }

    if (hasIFSCBank()) {
      if (!newBankAccountForm?.ifscCode?.trim()) {
        valid = false;
        _errors.ifscCode = translate('x_is_required', { x: translate('ifsc_code') });
      } else {
        _errors.ifscCode = '';
      }
    } else {
      _errors.ifscCode = '';
    }

    setErrors(_errors);

    return valid;
  };

  const hasIFSCBank = () => {
    // check if INR market && has "ifsc"
    return params?.domain === 'inr' && selectedBank?.name?.toLocaleLowerCase().indexOf('ifsc') > -1;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500 }}
      PaperProps={{
        sx: {
          border: '2px solid rgb(100, 0, 0)',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          // overflow: 'auto',
          p: '24px 8px 8px',
          m: 'auto',
          position: 'relative',
          marginTop: '50px',
          borderRadius: '10px',
        },
      }}
      scroll="body"
      // className="modal-container container-no-scrollbar"
      open={isAddMemberBankDialog?.open}
      onClose={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
    >
      {/* Title */}
      <Box
        sx={{
          p: 1,
          // background: 'linear-gradient(#00326d,#111923)'
        }}
      >
        <Typography sx={{ fontSize: '18px', color: '#f2c556', fontWeight: 'bolder', padding: 0 }}>
          {translate('withdraw_information')}
        </Typography>
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
        />
      </Box>

      <Box>
        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography>
              <span style={{ color: 'red', marginRight: 5 }}>*</span>
              {translate('bank')}
            </Typography>
          </Stack>

          <CustomSelect
            name="bank"
            value={newBankAccountForm?.bank}
            onChange={(e) => handleChange(e)}
            disableUnderline={true}
          >
            <option value="">{translate('please_select_a_bank')}</option>
            {bankList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </CustomSelect>
          {errors?.bank && <span style={{ fontSize: '.75rem', color: 'red' }}>{errors?.bank}</span>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography>
              <span style={{ color: 'red', marginRight: 5 }}>*</span>
              {translate('account_holder_name')}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems={'center'}
            sx={{
              borderRadius: '5px',
              background: '#fff',

              p: '4px 11px',
              mt: 1,
            }}
          >
            <InputBase
              value={newBankAccountForm.accountName}
              name="accountName"
              onChange={(e) => handleChange(e)}
              placeholder={translate('name_of_the_account_holder')}
              sx={{ color: '#271111', fontWeight: 700, fontSize: '14px', flexGrow: 1 }}
            />
          </Stack>
          {errors?.accountName && <span style={{ fontSize: '.75rem', color: 'red' }}>{errors?.accountName}</span>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography>
              <span style={{ color: 'red', marginRight: 5 }}>*</span>
              {selectedBank?.name === 'UPI' ? translate('upi_id') : translate('account_number')}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems={'center'}
            sx={{
              borderRadius: '5px',
              background: '#fff',
              p: '4px 11px',
              mt: 1,
            }}
          >
            <InputBase
              value={newBankAccountForm?.accountNumber}
              name="accountNumber"
              onChange={(e) => handleChange(e)}
              placeholder={selectedBank?.name === 'UPI' ? translate('enter_upi_id') : translate('fill_in_bank_account')}
              sx={{ color: '#271111', fontWeight: 700, fontSize: '14px', flexGrow: 1 }}
            />
            {selectedBank?.name === 'UPI' && (
              <Typography color="black" fontWeight={900}>
                @upi
              </Typography>
            )}
          </Stack>
          {errors?.accountNumber && <span style={{ fontSize: '.75rem', color: 'red' }}>{errors?.accountNumber}</span>}
        </Box>

        {hasIFSCBank() && (
          <Box sx={{ mb: '10px' }}>
            <Stack direction="row" alignItems={'center'}>
              <Typography>
                <span style={{ color: 'red', marginRight: 5 }}>*</span>
                {translate('ifsc_code')}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems={'center'}
              sx={{
                borderRadius: '5px',
                background: '#fff',
                p: '4px 11px',
                mt: 1,
              }}
            >
              <InputBase
                value={newBankAccountForm?.ifscCode}
                name="ifscCode"
                onChange={(e) => handleChange(e)}
                placeholder={translate('fil_in_ifsc_code')}
                sx={{ color: '#271111', fontWeight: 700, fontSize: '14px', flexGrow: 1 }}
              />
            </Stack>
            {errors?.ifscCode && <span style={{ fontSize: '.75rem', color: 'red' }}>{errors?.ifscCode}</span>}
          </Box>
        )}

        <button
          onClick={() => {
            addMemberBank();
            // setOpenWithdrawForm(false);
            // setWithdrawInfoSaved(true);
          }}
          disabled={isButtonDisabled}
          style={{
            width: '100%',
            lineHeight: 1.4,
            position: 'relative',
            textAlign: 'center',
            backgroundColor: '#f2c556',
            color: '#000',
            fontSize: '17px',
            fontFamily: 'sans-serif',
            border: 'none',
            padding: '15px 14px',
            borderRadius: 10,
            fontWeight: 'bold',
            marginRight: 8,
            cursor: 'pointer',
            marginBottom: 8,
            marginTop: '20px',
          }}
        >
          {translate('save')}
        </button>
      </Box>
    </Dialog>
  );
};

// WithdrawFormDialog.propTypes = {
//   openWitdrawForm: PropTypes.bool,
//   setOpenWithdrawForm: PropTypes.func,
//   handleFormChange: PropTypes.func,
//   withdrawInformation: PropTypes.object,
//   setWithdrawInfoSaved: PropTypes.func,
// };

export default WithdrawFormDialog;
